<script setup lang="ts">
definePageMeta({
  // layout: 'landing',
  middleware: undefined,
})

const isLogin = useisLogin()
const content = `本站將於[whales=3]2024/09/01起重啓禁用長期不登錄的用戶規則，詳情請前往 [url=https://wiki.orcinusorca.org/zh/rules/accountrules]【wiki-賬號規則】[/url] 查看
      影视类种子发种标准格式已更新，请前往【wiki-种子发种标准格式】查看
      Bug和建議反饋通道，请移步 【Github】提交`
</script>
<template>
  <div class=" ">
    <div class="relative">
      index
      <ClientOnly>
        isPWAInstalled : {{ $pwa?.isPWAInstalled }} offlineReady :
        {{ $pwa?.offlineReady }}
      </ClientOnly>
      isLogin:{{ isLogin }}
      
      <p class="text-9xl">index</p>
      <p class="text-9xl">index</p>
      <p class="text-9xl">index</p>
      <p class="text-9xl">index</p>
    </div>
  </div>
</template>
